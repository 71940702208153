import React from "react";
import Link from "gatsby-link";
import truncate from "utils/truncate";

const getStringFromPrismicObject = (obj) => {
  if (!obj) {
    return null;
  }
  const str = typeof obj === "string" ? obj : obj.text;
  if (!str) {
    return null;
  }
  return str;
};

const getHTMLFromPrismicObject = (obj) => {
  if (!obj) {
    return null;
  }
  const str = typeof obj === "string" ? obj : obj.html;
  if (!str) {
    return null;
  }
  return str;
};

export const validateText = (obj) => {
  if (!obj) {
    return false;
  }
  return obj && obj.text && obj.text.trim().length > 0 ? true : false;
};

export const validateHtml = (obj) => {
  if (!obj) {
    return false;
  }
  if (obj.text !== undefined) {
    return validateText(obj);
  }
  return obj && obj.html && obj.html.length > 0 ? true : false;
};

export const renderText = (
  obj,
  Tag = "div",
  classes = null,
  props = {},
  truncateWords = 0,
  suffix = null
) => {
  let str = getStringFromPrismicObject(obj);
  if (!str) {
    return null;
  }
  if (truncateWords > 0) {
    str = truncate(str, truncateWords);
  }
  if (suffix) {
    if (str.substr(str.length - suffix.length) !== suffix) {
      str = `${str}${suffix}`;
    }
  }
  return (
    <Tag className={classes} {...props}>
      {str}
    </Tag>
  );
};

export const renderHtml = (
  obj,
  Tag = "div",
  classes = null,
  props = {},
  truncateWords = 0,
  suffix = null
) => {
  let htmlStr = getHTMLFromPrismicObject(obj);
  if (!htmlStr) {
    return null;
  }
  if (truncateWords > 0) {
    htmlStr = truncate(htmlStr, truncateWords);
  }
  if (suffix) {
    // get lastindexof "<" - closing tag
    let lastIndex = htmlStr.lastIndexOf(`<`);
    if (htmlStr.substr(lastIndex - suffix.length, suffix.length) !== suffix) {
      htmlStr = `${htmlStr.substr(0, lastIndex)}${suffix}${htmlStr.substr(
        lastIndex
      )}`;
    }
  }

  return (
    <Tag
      className={classes}
      {...props}
      dangerouslySetInnerHTML={{ __html: htmlStr }}
    />
  );
};

export const renderButton = (
  label,
  link,
  children = null,
  classes = null,
  props = {}
) => {
  const labelStr = getStringFromPrismicObject(label);
  if (!labelStr || !link) {
    return null;
  }
  return (
    <Link className={classes} to={link} title={labelStr} {...props}>
      <span>{labelStr}</span>
      {children}
    </Link>
  );
};

export const PrismicLink = ({
  link,
  label = null,
  anchor,
  className,
  children,
  hostRef,
  ...props
}) => {
  if (!link || !link.raw) {
    console.error(
      "renderButton:: must provide a link with link.raw from graphql"
    );
    if (link) {
      console.log(link.url);
    }
    return null;
  }
  let labelStr = null;
  if (label) {
    labelStr = getStringFromPrismicObject(label);
  }

  if (!labelStr && !link) {
    return null;
  }

  const linkData = link.raw;
  let url;
  let target;

  if (linkData.link_type === "Document") {
    url = link.document[0].slug || "";
    if (anchor) {
      url = `${url}#${anchor}`;
    }
    if (
      !labelStr &&
      link.document[0].data.title &&
      link.document[0].data.title.text &&
      link.document[0].data.title.text.length > 0
    ) {
      labelStr = link.document[0].data.title.text;
    }
    if (url.charAt(0) !== "/") {
      url = `/${url}`;
    }
    return (
      <Link
        ref={hostRef || null}
        to={url}
        title={labelStr}
        className={className}
        {...props}
      >
        {children || labelStr}
      </Link>
    );
  }

  url = linkData.url || link.url;
  target = linkData.target || "_self";
  return (
    <a
      ref={hostRef}
      href={url}
      title={labelStr}
      className={className}
      target={target}
    >
      {children || labelStr}
    </a>
  );
};
