require("css/base.scss");
// require("utils/polyfills");

const React = require("react");
const Layout = require("components/Layout").default;

exports.wrapPageElement = ({ props, element }) => {
  let newProps = { ...props };
  return <Layout {...newProps}>{element}</Layout>;
};

exports.shouldUpdateScroll = () => {
  return false;
};
