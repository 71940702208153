import React, { Component } from "react";
import BreakpointListener from "components/BreakpointListener";
import Helmet from "react-helmet";
import MainNav from "components/MainNav";
import posed, { PoseGroup } from "react-pose";
import { sine } from "utils/easing";
import Footer from "components/Footer";

const Transition = posed.div({
  pageExit: {
    opacity: 0,
    transition: {
      opacity: {
        delay: 900,
        type: "tween",
        duration: 1,
      },
    },
  },
});

const Overlay = posed.figure({
  siteEnter: {
    x: 0,
  },
  out: {
    x: `100%`,
    transition: {
      x: { delay: 300, type: "tween", ease: sine.in, duration: 400 },
    },
  },
  in: {
    x: 0,
    applyAtStart: {
      x: `-100%`,
    },
    transition: {
      x: {
        type: "tween",
        ease: sine.out,
        duration: 500,
      },
    },
  },
});

export default class Layout extends Component {
  first = true;
  state = {
    overlayPose: "siteEnter",
    pathname: null,
    isNavClick: false,
    first: true,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.first) {
      return { pathname: props.location.pathname, first: false };
    }

    if (state.pathname !== props.location.pathname) {
      return {
        overlayPose: "in",
        pathname: props.location.pathname,
        isNavClick:
          props.location.state && props.location.state.isNavClick === true,
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.state.overlayPose === "in") {
      clearTimeout(this.transitionTimeout);
      this.transitionTimeout = setTimeout(() => {
        window.scrollTo(0, 0);
        this.setState({ overlayPose: "out" });
      }, 900);
    }
  }

  componentDidMount() {
    const { location } = this.props;
    const key =
      location.pathname.indexOf("#") >= 0
        ? location.pathname.split("#")[0]
        : location.pathname;
    setTimeout(() => {
      this.first = false;
      setTimeout(
        () => {
          this.setState({ first: false, overlayPose: "out" });
        },
        key === "/" ? 700 : 500
      );
    }, 0);
  }

  render() {
    const { children, location } = this.props;
    let key =
      location.pathname.indexOf("#") >= 0
        ? location.pathname.split("#")[0]
        : location.pathname;

    let title = this.props.data.page.data.title ||
      this.props.data.page.data.name || { text: "home" };

    const overlayPreEnterPose = this.state.first
      ? "siteEnter"
      : this.state.overlayPose;
    // console.log("overlay pre:", overlayPreEnterPose);

    return (
      <React.Fragment>
        <Helmet>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/icons/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/icons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/icons/favicon-16x16.png"
          />
          <link rel="manifest" href="/manifest.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#b71234" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#b71234" />
        </Helmet>
        <div id="placeholder" />
        <BreakpointListener />
        <MainNav
          homeUrl={"/"}
          location={location}
          currentPageTitle={title.text}
          overlay={
            <Overlay
              initialPose="sitEnter"
              pose={overlayPreEnterPose}
              isNavClick={this.state.isNavClick}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: `100%`,
                height: `100%`,
                zIndex: 100,
                backgroundColor: `#b71234`,
                display: `block`,
                willChange: `transform`,
              }}
            />
          }
        />
        <PoseGroup
          animateOnMount={false}
          flipMove={false}
          enterAfterExit={true}
          preEnterPose={this.state.first ? "siteEnter" : "prePageEnter"}
          enterPose="pageEnter"
          exitPose="pageExit"
        >
          <Transition
            isNavClick={location.state && location.state.isNavClick === true}
            id="main"
            role="main"
            key={key}
          >
            {children}
            {location.pathname !== "/" && <Footer />}
          </Transition>
        </PoseGroup>
      </React.Fragment>
    );
  }
}
