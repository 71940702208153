import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { PrismicLink, renderHtml, renderText } from "utils/renderHelpers";
import { StaticQuery, graphql } from "gatsby";

import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Footer from "react-bulma-components/lib/components/footer";
import Link from "gatsby-link";
import React from "react";
import styles from "./Footer.module.scss";

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          prismicFoot {
            ...FooterFragment
          }
        }
      `}
      render={(data) => {
        const {
          heading,
          north_america_contact,
          // north_america_phone,
          international_contact,
          // international_phone,
          canada_offices,
          us_offices,
          europe_offices,
          asia_offices,
          copyright,
          facebook_url,
          twitter_url,
          linkedin_url,
          instagram_url,
          body,
        } = data.prismicFoot.data;

        const officesList = [
          us_offices,
          canada_offices,
          europe_offices,
          asia_offices,
        ];

        const naContact = north_america_contact.document
          ? north_america_contact.document[0]
          : null;
        const intContact = international_contact.document
          ? international_contact.document[0]
          : null;

        // const naPhone =
        //   north_america_phone && north_america_phone.length > 0
        //     ? north_america_phone
        //     : intContact && intContact.data.phone_number
        //     ? intContact.data.phone_number
        //     : null;

        // const intPhone =
        //   international_phone && international_phone.length > 0
        //     ? international_phone
        //     : intContact && intContact.data.phone_number
        //     ? intContact.data.phone_number
        //     : null;

        return (
          <Footer className={styles.footer}>
            <Container>
              <Columns mobile multiline className={styles.mainColumns}>
                <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                  {renderHtml(heading, `div`, styles.heading)}
                </Columns.Column>
                <Columns.Column mobile={{ size: 12 }} tablet={{ size: 3 }}>
                  <Columns mobile multiline>
                    <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                      <h3 className={styles.listHeading}>Drop us a line.</h3>
                    </Columns.Column>

                    <Columns.Column
                      mobile={{ size: 6 }}
                      tablet={{ size: 12 }}
                      className={`is-order-2-mobile is-order-2-tablet`}
                    >
                      {naContact && (
                        <article className={styles.contact}>
                          <h4>North America</h4>
                          <p>{naContact.data.name.text}</p>
                          {/* {naPhone && (
                            <React.Fragment>
                              <a
                                className={styles.email}
                                href={`tel:${naPhone}`}
                              >
                                {naPhone}
                              </a>
                              <br />
                            </React.Fragment>
                          )} */}
                          {renderText(naContact.data.email, `a`, styles.email, {
                            target: `_blank`,
                            href: `mailto:${naContact.data.email.text}`,
                          })}
                        </article>
                      )}
                      {intContact && (
                        <article className={styles.contact}>
                          <h4>EMEA / APAC</h4>
                          <p>{intContact.data.name.text}</p>
                          {/* {intPhone && (
                            <React.Fragment>
                              <a
                                className={styles.email}
                                href={`tel:${intPhone}`}
                              >
                                {intPhone}
                              </a>
                              <br />
                            </React.Fragment>
                          )} */}
                          {renderText(
                            intContact.data.email,
                            `a`,
                            styles.email,
                            {
                              target: `_blank`,
                              href: `mailto:${intContact.data.email.text}`,
                            }
                          )}
                        </article>
                      )}
                    </Columns.Column>
                  </Columns>
                </Columns.Column>
                <Columns.Column
                  mobile={{ size: 12 }}
                  tablet={{ size: 8, offset: 1 }}
                >
                  <Columns mobile multiline className={styles.hasOfficeLists}>
                    <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                      <h3 className={styles.listHeading}>Office hubs</h3>
                    </Columns.Column>
                    {officesList &&
                      officesList.length &&
                      officesList.map((list, idx) => (
                        <Columns.Column
                          mobile={{ size: 6 }}
                          tablet={{ size: 3 }}
                          key={`office-list-${idx}`}
                        >
                          <ul className={styles.officeList}>
                            {list.map(({ office }) => {
                              const doc = office.document[0];
                              const { slug, id, data } = doc;
                              return (
                                <li key={id}>
                                  <Link to={`/${slug}`}>{data.city.text}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        </Columns.Column>
                      ))}
                  </Columns>
                </Columns.Column>
              </Columns>
              <Columns mobile multiline className={styles.bottomRow}>
                <Columns.Column
                  mobile={{ size: 12 }}
                  tablet={{ size: 4 }}
                  className={"is-flex-tablet"}
                >
                  <ul className={styles.social}>
                    {facebook_url && (
                      <li>
                        <a
                          title="Facebook"
                          href={facebook_url}
                          target={`_blank`}
                        >
                          <span className="is-sr-only">Facebook</span>
                          <i className="icon">
                            <FaFacebookF />
                          </i>
                        </a>
                      </li>
                    )}
                    {twitter_url && (
                      <li>
                        <a title="Twitter" href={twitter_url} target={`_blank`}>
                          <span className="is-sr-only">Twitter</span>
                          <i className="icon">
                            <FaTwitter />
                          </i>
                        </a>
                      </li>
                    )}
                    {linkedin_url && (
                      <li>
                        <a
                          title="LinkedIn"
                          href={linkedin_url}
                          target={`_blank`}
                        >
                          <span className="is-sr-only">LinkedIn</span>
                          <i className="icon">
                            <FaLinkedinIn />
                          </i>
                        </a>
                      </li>
                    )}
                    {instagram_url && (
                      <li>
                        <a
                          title="Instagram"
                          href={instagram_url}
                          target={`_blank`}
                        >
                          <span className="is-sr-only">Instagram</span>
                          <i className="icon">
                            <FaInstagram />
                          </i>
                        </a>
                      </li>
                    )}
                  </ul>
                </Columns.Column>
                <Columns.Column
                  mobile={{ size: 12 }}
                  tablet={{ size: 8 }}
                  className={`is-flex-tablet ${styles.hasPageLinks}`}
                >
                  {renderHtml(copyright, `div`, styles.copyright)}
                  {body && body.length >= 1 && (
                    <ul className={styles.pageLinks}>
                      {body.map((item) => {
                        //const doc = link.document[0];
                        return item.items.map((i) => {
                          const { label, link } = i;
                          return (
                            <li key={link.raw.url}>
                              <PrismicLink label={label} link={link} />
                            </li>
                          );
                        });
                      })}
                    </ul>
                  )}
                </Columns.Column>
              </Columns>
            </Container>
          </Footer>
        );
      }}
    />
  );
};
