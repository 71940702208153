import { FaBars, FaTimes } from "react-icons/fa";
import React, { PureComponent } from "react";
import { StaticQuery, graphql } from "gatsby";
import posed, { PoseGroup } from "react-pose";

import BreakpointListener from "components/BreakpointListener";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import Link from "gatsby-link";
import Logo from "./logo";
import LogoInverted from "./logo-inverted";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import events from "utils/events";
import { renderText } from "utils/renderHelpers";
import { sine } from "utils/easing";
import styles from "./MainNav.module.scss";

const cx = classNames.bind(styles);
const AnimatedPageTitle = posed.div({
  off: { x: -10, opacity: 0 },
  on: {
    x: 0,
    opacity: 1,
    delay: 300,
    transition: {
      type: "tween",
      duration: 300,
      ease: sine.out,
    },
  },
  out: {
    x: 10,
    opacity: 0,
    transition: {
      type: "tween",
      duration: 300,
      ease: sine.in,
    },
  },
});

const Sidebar = posed.aside({});

const Drawer = posed.div({
  open: {
    staggerChildren: 50,
    delayChildren: 400,
    x: ({ isMobile }) => (isMobile ? 100 : 0),
    transition: {
      type: "tween",
      ease: sine.out,
      duration: 400,
    },
  },
  hardClosed: {
    beforeChildren: true,
    x: `-100%`,
    transition: {
      type: "tween",
      duration: 1,
      delay: 900,
    },
  },
  closed: {
    beforeChildren: true,
    x: `-100%`,
    transition: {
      type: "tween",
      ease: sine.in,
      duration: 250,
    },
  },
});

const DrawerLink = posed.li({
  open: {
    applyAtStart: {
      y: 20,
      opacity: 0,
    },
    y: 0,
    opacity: 1,
    transition: {
      type: "tween",
      ease: sine.out,
      duration: 300,
    },
  },
});

const FRLink = posed.a({
  open: {
    applyAtStart: {
      y: 20,
      opacity: 0,
    },
    y: 0,
    opacity: 1,
    transition: {
      type: "tween",
      ease: sine.out,
      duration: 300,
    },
  },
});

const AnimatedLogo = posed.div({
  mobilePre: {
    y: -100,
    opacity: 0,
  },
  in: {
    y: 0,
    opacity: 1,
    transition: {
      y: {
        type: "tween",
        ease: sine.out,
        duration: 300,
      },
      opacity: {
        type: "tween",
        ease: sine.out,
        duration: 200,
      },
    },
  },
  inOn: {
    y: 0,
    opacity: 1,
    transition: {
      y: {
        type: "tween",
        ease: sine.out,
        duration: 300,
      },
      opacity: {
        type: "tween",
        ease: sine.out,
        duration: 200,
      },
    },
  },
  out: {
    y: -100,
    opacity: 0,
    transition: {
      y: {
        type: "tween",
        ease: sine.in,
        duration: 300,
      },
      opacity: {
        type: "tween",
        ease: sine.in,
        duration: 200,
      },
    },
  },
});

const MobileTrigger = posed.button({
  mobilePre: {
    x: 150,
    opacity: 0,
  },
  in: {
    x: 0,
    opacity: 1,
    transition: {
      x: {
        type: "tween",
        ease: sine.out,
        duration: 300,
      },
      opacity: {
        type: "tween",
        ease: sine.out,
        duration: 200,
      },
    },
  },
  inOn: {
    x: 0,
    opacity: 1,
    transition: {
      x: {
        type: "tween",
        ease: sine.out,
        duration: 300,
      },
      opacity: {
        type: "tween",
        ease: sine.out,
        duration: 200,
      },
    },
  },
  out: {
    x: 150,
    opacity: 0,
    transition: {
      x: {
        type: "tween",
        ease: sine.in,
        duration: 300,
      },
      opacity: {
        type: "tween",
        ease: sine.in,
        duration: 200,
      },
    },
  },
});

export default class MainNav extends PureComponent {
  static propTypes = {
    homeUrl: PropTypes.string.isRequired,
    links: PropTypes.array,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    homeUrl: "/",
    links: [],
    locale: "en",
  };

  static getDerivedStateFromProps(props, state) {
    if (props.location.key !== state.locationKey) {
      return {
        isOpen: false,
        isNavClick:
          props.location.state && props.location.state.isNavClick
            ? true
            : false,
        locationKey: props.location.key,
      };
    }
    return null;
  }

  state = {
    isOpen: false,
    isAtTop: true,
  };

  componentDidMount() {
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
    window.addEventListener("scroll", this.onScroll);

    this.setState({
      isMobile:
        BreakpointListener.size === "tablet" ||
        BreakpointListener.size === "mobile",
      isAtTop: window.scrollY === 0,
      mobileTriggerShowing: window.scrollY === 0,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeydown);
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = (e) => {
    if (window.scrollY === 0) {
      this.setState({ isAtTop: true, mobileTriggerShowing: true });
    } else if (window.scrollY > 0) {
      this.setState({ isAtTop: false });
    }

    if (this.lastScrollY > window.scrollY) {
      clearTimeout(this.mobileTriggerTimeout);
      this.setState(
        {
          mobileTriggerShowing: true,
          isAtTop: window.scrollY === 0,
        },
        () => {
          this.mobileTriggerTimeout = setTimeout(() => {
            this.setState({
              mobileTriggerShowing: false,
              isAtTop: window.scrollY === 0,
            });
          }, 3000);
        }
      );
    } else {
      clearTimeout(this.mobileTriggerTimeout);
      this.mobileTriggerTimeout = setTimeout(() => {
        this.setState({
          mobileTriggerShowing: false,
          isAtTop: window.scrollY === 0,
        });
      }, 1500);
    }

    this.lastScrollY = window.scrollY;
  };

  onBreakpoint = ({ newSize }) => {
    const isAtTop = this.state.isAtTop || window.scrollY === 0;
    const isMobile = newSize === "tablet" || newSize === "mobile";

    this.setState({
      isMobile,
      mobileTriggerShowing: isMobile,
      isAtTop,
    });
  };

  onKeydown = (e) => {
    switch (e.keyCode) {
      case 27:
        this.setClosed();
        break;
      default:
        break;
    }
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  setOpen = () => {
    this.setState({ isOpen: true });
  };

  setClosed = () => {
    this.setState({ isOpen: false, isNavClick: false });
  };

  componentDidUpdate() {
    window.removeEventListener("keydown", this.onKeydown);
    if (this.state.isOpen) {
      window.addEventListener("keydown", this.onKeydown);
    }
  }

  render() {
    const { homeUrl, currentPageTitle } = this.props;
    const {
      isOpen,
      isNavClick,
      isMobile,
      isAtTop,
      mobileTriggerShowing,
    } = this.state;

    const sidebarPose = isOpen ? "open" : isNavClick ? "hardClosed" : "closed";
    return (
      <StaticQuery
        query={graphql`
          query MainNavQuery {
            nav: prismicMainNaviga {
              ...MainNavigationFragment
            }
          }
        `}
        render={(data) => {
          const { nav } = data;
          const { links } = nav.data;

          return (
            <React.Fragment>
              {isMobile && (
                <React.Fragment key={"mobileButtons"}>
                  <AnimatedLogo
                    pose={isAtTop ? "inOn" : "out"}
                    className={styles.mobileLogo}
                  >
                    <Link to={homeUrl} state={{ isNavClick: true }}>
                      <LogoInverted width={68} />
                    </Link>
                  </AnimatedLogo>
                  <MobileTrigger
                    onClick={isOpen ? this.setClosed : this.setOpen}
                    aria-controls="drawer"
                    aria-expanded={isOpen}
                    className={styles.mobileTrigger}
                    pose={mobileTriggerShowing || isOpen ? "inOn" : "out"}
                  >
                    <span className="is-sr-only">
                      {isOpen ? `close menu` : `open menu`}
                    </span>
                    {!isOpen && (
                      <i>
                        <FaBars />
                      </i>
                    )}
                    {isOpen && (
                      <i>
                        <FaTimes />
                      </i>
                    )}
                  </MobileTrigger>
                </React.Fragment>
              )}
              <Sidebar id="menu" pose={sidebarPose}>
                <nav className={cx({ mainNav: true })}>
                  <div className={styles.navbar}>
                    <div style={{ width: `100%`, display: `block` }}>
                      <Link
                        to={homeUrl}
                        state={{ isNavClick: true }}
                        className={styles.brand}
                        onClick={(e) => {
                          e.target.blur();
                        }}
                      >
                        <span
                          tabIndex={-1}
                          focusable="false"
                          className="is-sr-only"
                        >
                          Home
                        </span>
                        <Logo tabIndex={-1} focusable="false" width={75} />
                      </Link>
                    </div>
                    <button
                      className={styles.toggler}
                      onClick={() =>
                        isOpen ? this.setClosed() : this.setOpen()
                      }
                      aria-controls="drawer"
                      aria-expanded={isOpen}
                    >
                      <span className="is-sr-only">
                        {isOpen ? `close menu` : `open menu`}
                      </span>
                      {!isOpen && (
                        <i key={`nav-open-icon`}>
                          <FaBars style={{ transform: `rotate(90deg)` }} />
                        </i>
                      )}
                      {isOpen && (
                        <i key={`nav-close-icon`}>
                          <FaTimes style={{ transform: `rotate(90deg)` }} />
                        </i>
                      )}
                    </button>
                    <PoseGroup
                      enterAfterExit={true}
                      animateOnMount={false}
                      preEnterPose={"off"}
                      enterPose={"on"}
                      exitPose={"out"}
                    >
                      <AnimatedPageTitle
                        key={this.props.location.pathname}
                        className={styles.isCurrentLocation}
                      >
                        <span>{currentPageTitle}</span>
                      </AnimatedPageTitle>
                    </PoseGroup>
                  </div>
                  <Drawer className={styles.drawer} isMobile={isMobile}>
                    <Container className={styles.container}>
                      <Columns mobile>
                        <Columns.Column
                          mobile={{ size: 11 }}
                          tablet={{ size: 11 }}
                          desktop={{ size: 10, offset: 1 }}
                        >
                          <ul className={styles.hasLinks}>
                            {links.map(({ link, label }, idx) => {
                              let { url, raw } = link;
                              let current =
                                link.document &&
                                `/${link.document[0].slug}` ===
                                  this.props.location.pathname;
                              return (
                                <DrawerLink key={`mainnavlink-${idx}`}>
                                  {current ? (
                                    renderText(
                                      link.document[0].data.title,
                                      `span`,
                                      `is-uppercase has-text-weight-bold is-nav-link ${cx(
                                        { navLink: true, current: true }
                                      )}`
                                    )
                                  ) : link.document ? (
                                    <Link
                                      className={`is-uppercase has-text-weight-bold is-nav-link ${styles.navLink}`}
                                      to={`/${link.document[0].slug}`}
                                      state={{ isNavClick: true }}
                                      title={link.document[0].data.title.text}
                                    >
                                      {renderText(
                                        link.document[0].data.title,
                                        `span`
                                      )}
                                    </Link>
                                  ) : (
                                    <a
                                      className="is-uppercase has-text-weight-bold is-nav-link"
                                      href={url}
                                      target={
                                        raw
                                          ? raw.target
                                            ? raw.target
                                            : null
                                          : null
                                      }
                                    >
                                      <span>{label.text || url}</span>
                                    </a>
                                  )}
                                </DrawerLink>
                              );
                            })}
                          </ul>
                          <FRLink
                            href="https://citoyenoptimum.com/"
                            title="Citoyen Optimum"
                            style={{ marginTop: `-2rem`, display: `block` }}
                          >
                            FR
                          </FRLink>
                        </Columns.Column>
                      </Columns>
                    </Container>
                  </Drawer>
                  {this.props.overlay}
                </nav>
              </Sidebar>
            </React.Fragment>
          );
        }}
      />
    );
  }
}
