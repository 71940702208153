import React, { Component } from 'react';
import PropTypes from 'prop-types';
import mitt from 'mitt';
import events from 'utils/events';

import styles from './BreakpointListener.module.scss';

export default class BreakpointListener extends Component {
  static size = 'fullhd';
  static gap = 24;

  static propTypes = {
    defaultBreakpoint: PropTypes.string
  };

  static defaultProps = {
    defaultBreakpoint: 'fullhd'
  };

  static emit(type, data) {
    if (!BreakpointListener.emitter) {
      BreakpointListener.emitter = mitt();
    }
    BreakpointListener.emitter.emit(type, data);
  }

  static on(type, cb) {
    if (!BreakpointListener.emitter) {
      BreakpointListener.emitter = mitt();
    }
    return BreakpointListener.emitter.on(type, cb);
  }

  static off(type, cb) {
    if (!BreakpointListener.emitter) {
      return;
    }
    return BreakpointListener.emitter.off(type, cb);
  }

  state = {
    breakpoint: null
  };

  componentDidMount() {
    if (typeof window === `undefined`) {
      return;
    }
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  shouldComponentUpdate() {
    return false;
  }

  getBreakpointData = () => {
    if (typeof window === `undefined` || !this.__el) {
      return this.props.defaultBreakpoint;
    }
    let size = window.getComputedStyle(this.__el).content.replace(/"/g, '');
    let gap = parseFloat(
      window.getComputedStyle(this.__el).columnGap.replace(/"/g, '')
    );

    BreakpointListener.gap = gap;
    BreakpointListener.size = size;

    return { size, gap };
  };

  onResize = () => {
    let bpData = this.getBreakpointData();
    let oldSize = this.state.breakpoint;
    let newSize = bpData.size;
    if (newSize === oldSize) {
      return;
    }
    this.setState({ breakpoint: newSize }, () => {
      BreakpointListener.emit(events.breakpoint, {
        oldSize,
        newSize,
        gap: bpData.gap
      });
    });
  };

  render() {
    return (
      <div
        id="breakpoint-listener"
        className={styles.listener}
        ref={r => (this.__el = r)}
      />
    );
  }
}
